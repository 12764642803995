import React from "react";
import { Tab, Tabs } from "react-bootstrap";

const TabComponent = ({ selectedTab, handleTabSelect, tabs }) => {
  return (
    <Tabs
      id="fill-tab-example"
      className=""
      activeKey={selectedTab}
      onSelect={handleTabSelect}
      defaultActiveKey={tabs[0]?.event_key}
      fill
    >
      {tabs?.map((tab) => (
        <Tab
          disabled={
            tab.event_key == "documents" ||
            tab.event_key == "financial-details" ||
            tab.event_key == "education" ||
            tab.event_key == "leaves"
          }
          key={tab.event_key}
          eventKey={tab.event_key}
          title={tab.title}
        >
          {tab.component}
        </Tab>
      ))}
    </Tabs>
  );
};

export default TabComponent;
