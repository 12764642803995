import React from "react";

const DashboardIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_186_3048)">
        <path
          d="M1 2.76C1 2.14394 1 1.83591 1.11989 1.60061C1.22535 1.39363 1.39363 1.22535 1.60061 1.11989C1.83591 1 2.14394 1 2.76 1H8.04C8.65606 1 8.96409 1 9.19939 1.11989C9.40637 1.22535 9.57465 1.39363 9.68011 1.60061C9.8 1.83591 9.8 2.14394 9.8 2.76V8.04C9.8 8.65606 9.8 8.96409 9.68011 9.19939C9.57465 9.40637 9.40637 9.57465 9.19939 9.68011C8.96409 9.8 8.65606 9.8 8.04 9.8H2.76C2.14394 9.8 1.83591 9.8 1.60061 9.68011C1.39363 9.57465 1.22535 9.40637 1.11989 9.19939C1 8.96409 1 8.65606 1 8.04V2.76Z"
          stroke="#3E6E4B"
          strokeWidth="2"
        />
        <path
          d="M1 15.96C1 15.3439 1 15.0359 1.11989 14.8006C1.22535 14.5936 1.39363 14.4254 1.60061 14.3199C1.83591 14.2 2.14394 14.2 2.76 14.2H8.04C8.65606 14.2 8.96409 14.2 9.19939 14.3199C9.40637 14.4254 9.57465 14.5936 9.68011 14.8006C9.8 15.0359 9.8 15.3439 9.8 15.96V21.24C9.8 21.8561 9.8 22.1641 9.68011 22.3994C9.57465 22.6064 9.40637 22.7746 9.19939 22.8801C8.96409 23 8.65606 23 8.04 23H2.76C2.14394 23 1.83591 23 1.60061 22.8801C1.39363 22.7746 1.22535 22.6064 1.11989 22.3994C1 22.1641 1 21.8561 1 21.24V15.96Z"
          stroke="#3E6E4B"
          strokeWidth="2"
        />
        <path
          d="M14.2 2.76C14.2 2.14394 14.2 1.83591 14.3199 1.60061C14.4254 1.39363 14.5936 1.22535 14.8006 1.11989C15.0359 1 15.3439 1 15.96 1H21.24C21.8561 1 22.1641 1 22.3994 1.11989C22.6064 1.22535 22.7746 1.39363 22.8801 1.60061C23 1.83591 23 2.14394 23 2.76V8.04C23 8.65606 23 8.96409 22.8801 9.19939C22.7746 9.40637 22.6064 9.57465 22.3994 9.68011C22.1641 9.8 21.8561 9.8 21.24 9.8H15.96C15.3439 9.8 15.0359 9.8 14.8006 9.68011C14.5936 9.57465 14.4254 9.40637 14.3199 9.19939C14.2 8.96409 14.2 8.65606 14.2 8.04V2.76Z"
          stroke="#3E6E4B"
          strokeWidth="2"
        />
        <path
          d="M14.2 15.96C14.2 15.3439 14.2 15.0359 14.3199 14.8006C14.4254 14.5936 14.5936 14.4254 14.8006 14.3199C15.0359 14.2 15.3439 14.2 15.96 14.2H21.24C21.8561 14.2 22.1641 14.2 22.3994 14.3199C22.6064 14.4254 22.7746 14.5936 22.8801 14.8006C23 15.0359 23 15.3439 23 15.96V21.24C23 21.8561 23 22.1641 22.8801 22.3994C22.7746 22.6064 22.6064 22.7746 22.3994 22.8801C22.1641 23 21.8561 23 21.24 23H15.96C15.3439 23 15.0359 23 14.8006 22.8801C14.5936 22.7746 14.4254 22.6064 14.3199 22.3994C14.2 22.1641 14.2 21.8561 14.2 21.24V15.96Z"
          stroke="#3E6E4B"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_186_3048">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DashboardIcon;
