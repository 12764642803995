import { Col, Row } from "react-bootstrap";
import "./style.css";
import logo from "../../assets/images/logo.png";
import loading from "../../assets/images/loading.png";

const Loading = () => {
  return (
    <div className="loading-container">
      <Row className="loading-container-row-1">
        <Col className="loading-container-col-1">
          <img className="loading-container-img-1" src={logo} alt="img" />
          <img
            className="loading-container-img-2"
            width={40}
            height={40}
            src={loading}
            alt="img"
          />
        </Col>
      </Row>
      <Row className="loading-container-row-2">
        <Col>It's taking a moment, your patience is appreciated...</Col>
      </Row>
    </div>
  );
};

export default Loading;
