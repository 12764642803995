import { Col, Row } from "react-bootstrap";
import tumer from "../../assets/images/Tumer.svg";
import minus from "../../assets/images/minus.png";
import plant from "../../assets/images/plant.png";
import plus from "../../assets/images/plus.png";
import "./style.css";
import { DatePicker } from "antd";
import RequestedPlantsCard from "../RequestedPlantsCard/RequestedPlantsCard";
import { useEffect, useState } from "react";

// const onChange = (date, dateString) => {
//   console.log(date, dateString);
// };

const RequestedPlants = ({ width, data, setExtractedPlants }) => {
  // console.log("data", data);
  const [plants, setPlants] = useState(data?.fullData?.plants || []);
  const [Updatedplants, setUpdatedPlants] = useState(
    data?.fullData?.RequestPlants || []
  );

  const handleQuantityChange = (index, newQuantity) => {
    const updatedPlant = Updatedplants?.map((plant, i) =>
      i === index ? { ...plant, approved_qty: newQuantity } : plant
    );
    setUpdatedPlants(updatedPlant);
  };

  // Extract the desired properties and save to state
  useEffect(() => {
    const extracted = Updatedplants.map((plant) => ({
      id: plant.id,
      plant_id: plant.plant_id,
      approved_qty: plant.approved_qty,
    }));
    setExtractedPlants(extracted);
  }, [Updatedplants]);

  return (
    // <div style={{ width: width >= 35 ? "100%" : `100%` }}>
    <div>
      <Row className="requested_plants_container">
        {/* <div
          style={{
            display: "flex",
            // marginBottom: "8px",
            marginLeft: 0,
          }}
        >
          <p className="requestPlants">Requested plants</p>
          <button className="assign-button">Save</button>
        </div> */}
        <p className="requestPlants">Requested plants</p>
        {data?.status !== "APPROVED" &&
        data?.status !== "REJECTED" &&
        data?.status !== "IN_PROGRES"
          ? plants?.map((plant, i) => (
              <RequestedPlantsCard
                key={i}
                width={width}
                data={plant}
                buttons={true}
                index={i}
                onQuantityChange={handleQuantityChange}
              />
            ))
          : data?.fullData?.RequestPlants?.map((plant, i) => {
              return (
                <RequestedPlantsCard
                  key={i}
                  width={width}
                  sidebarData={plant}
                  buttons={false}
                  index={i}
                  onQuantityChange={handleQuantityChange}
                />
              );
            })}

        {/* <RequestedPlantsCard width={width} />
        <RequestedPlantsCard width={width} /> */}
        {/* <Col>
          <div className="totalDiv">
            <p className="SubTotal">Total</p>
            <p className="TotalPlantCount">24</p>
          </div>
        </Col> */}
      </Row>
      {/* <Row>
        <Col>
          <div className="ml-4 mt-4">
            <p className="requestPlants">Deliverable Date</p>
            <DatePicker onChange={onChange} style={{ marginLeft: "10PX" }} />
          </div>
        </Col>
      </Row> */}
    </div>
  );
};
export default RequestedPlants;
