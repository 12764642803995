import React from "react";
import "./style.css";
import { Col, Row } from "react-bootstrap";

const RequestTabForm = ({
  request,
  requestDate,
  service,
  requesterName,
  emirateID,
  mobileNo,
  email,
  address,
  data,
  requestValue,
}) => {
  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    if (isNaN(dateObject)) {
      return null;
    }
    // Format date and time strings
    const formattedDate = `${dateObject.getFullYear()}-${
      dateObject.getMonth() + 1
    }-${dateObject.getDate()}`;

    return `${formattedDate || ""}`;
  };

  return (
    <div className="tabColor">
      <Row>
        {request && (
          <Col xs={12} className="my-2">
            <label className="inputTitle">{request}</label>
            <input className="inputOfTab" value={data?.number || ""} disabled />
          </Col>
        )}
        {requestDate && (
          <Col xs={12} className="my-2">
            <label className="inputTitle">{requestDate}</label>
            <input
              className="inputOfTab"
              value={formatDate(data?.date) || ""}
              disabled
            />
          </Col>
        )}
        {service && (
          <Col xs={12} className="my-2">
            <label className="inputTitle">{service}</label>
            <input className="inputOfTab" value={data?.name || ""} disabled />
          </Col>
        )}
        {/* {requestValue && (
          <Col xs={12} className="my-2">
            <p className="inputTitle" style={{marginBottom:"15px"}}>{requestValue}</p>
            {data?.fullData?.request
              .filter((item) => item.feild_type === "text" && item.value)
              .map((filteredItem) => (
                <div key={filteredItem.id}>
                  <p>
                    <strong>{filteredItem.name}:</strong> <br/>
                    {filteredItem.value}
                  </p>
                </div>
              ))}
          </Col>
        )} */}
        {requestValue && (
          <Col xs={12} className="my-2">
            <p className="inputTitle" style={{ marginBottom: "15px" }}>
              {requestValue}
            </p>
            {data?.fullData?.request
              .filter((item) => item.value)
              .map((filteredItem, i) => (
                <div key={i}>
                  {filteredItem.feild_type === "text" && (
                    <p>
                      <strong>{filteredItem.name}:</strong> <br />
                      {filteredItem.value}
                    </p>
                  )}
                  {filteredItem.feild_type === "dropdown" && (
                    <p>
                      <strong>{filteredItem.systemlist.list_name} :</strong>
                      <br />
                      {filteredItem.value.value}
                    </p>
                  )}
                </div>
              ))}
          </Col>
        )}
        {requesterName && (
          <Col xs={12} className="my-2">
            <label className="inputTitle">{requesterName}</label>
            <input
              className="inputOfTab"
              value={data?.fullData?.customer?.fullname || ""}
              disabled
            />
          </Col>
        )}
        {emirateID && (
          <Col xs={12} className="my-2">
            <label className="inputTitle">{emirateID}</label>
            <input
              className="inputOfTab"
              value={data?.section || ""}
              disabled
            />
          </Col>
        )}
        {mobileNo && (
          <Col xs={12} className="my-2">
            <label className="inputTitle">{mobileNo}</label>
            <input
              className="inputOfTab"
              value={data?.fullData?.customer?.mobile_no || ""}
              disabled
            />
          </Col>
        )}
        {email && (
          <Col xs={12} className="my-2">
            <label className="inputTitle">{email}</label>
            <input
              className="inputOfTab"
              value={data?.fullData?.customer?.email || ""}
              disabled
            />
          </Col>
        )}
        {address && (
          <Col xs={12} className="my-2">
            <label className="inputTitle">{address}</label>
            <input
              className="inputOfTab"
              value={`${data?.fullData?.customer?.address_area || ""} Street ${
                data?.fullData?.customer?.address_street || ""
              } Building ${
                data?.fullData?.customer?.address_building || ""
              } Flat ${data?.fullData?.customer?.address_flat || ""}`}
              disabled
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default RequestTabForm;
