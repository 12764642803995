import Layout from "../Layout/Layout";

const Employee = () => {
  return (
    <>
      <Layout isAdmin={false} />
    </>
  );
};

export default Employee;
