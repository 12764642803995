function CopyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 44 44"
      fill="none"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H36C40.4183 0 44 3.58172 44 8V36C44 40.4183 40.4183 44 36 44H8C3.58172 44 0 40.4183 0 36V8Z"
        fill="#FBF1ED"
      />
      <path
        d="M33 16.145V26.4243C33 27.5217 33 28.0704 32.8002 28.4896C32.6244 28.8582 32.3439 29.158 31.999 29.3459C31.6068 29.5594 31.0934 29.5594 30.0667 29.5594C29.6617 29.5594 29.3333 29.9036 29.3333 30.3281V31.5112C29.3333 31.9154 28.8914 32.1443 28.5851 31.8988L26.7184 30.4025C26.3284 30.0898 26.1332 29.9334 25.9178 29.8224C25.7265 29.7238 25.5238 29.6519 25.3148 29.6084C25.0793 29.5594 24.8337 29.5594 24.3426 29.5594H19.5555M13.9333 12H26.4C27.4268 12 27.9401 12 28.3323 12.2136C28.6773 12.4014 28.9577 12.7012 29.1335 13.0699C29.3333 13.489 29.3333 14.0377 29.3333 15.1351V22.5809C29.3333 23.6782 29.3333 24.2269 29.1335 24.6461C28.9577 25.0148 28.6773 25.3145 28.3323 25.5024C27.9401 25.7159 27.4268 25.7159 26.4 25.7159H19.6574C19.1663 25.7159 18.9207 25.7159 18.6852 25.7649C18.4762 25.8084 18.2735 25.8803 18.0822 25.9789C17.8667 26.0899 17.6717 26.2463 17.2816 26.559L15.4149 28.0553C15.1086 28.3008 14.6667 28.0719 14.6667 27.6677V26.4846C14.6667 26.0601 14.3383 25.7159 13.9333 25.7159C12.9066 25.7159 12.3932 25.7159 12.001 25.5024C11.6561 25.3145 11.3756 25.0148 11.1998 24.6461C11 24.2269 11 23.6782 11 22.5809V15.1351C11 14.0377 11 13.489 11.1998 13.0699C11.3756 12.7012 11.6561 12.4014 12.001 12.2136C12.3932 12 12.9066 12 13.9333 12Z"
        stroke="#6E4C3E"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default CopyIcon;
