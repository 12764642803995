import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./style.css";
import AlarmIcon from "../../assets/svg/AlarmIcon";
import QuestionIcon from "../../assets/svg/QuestionIcon";
import SearchIcon from "../../assets/svg/SearchIcon";
import HeadPhoneIcon from "../../assets/svg/HeadSetIcon";
import BellIcon from "../../assets/svg/BellIcon";
import CopyIcon from "../../assets/svg/CopyIcon";
import arabicImage from "../../assets/images/image-arabic.jpg";
import logo from "../../assets/images/logo.png";

const CustomHeader = ({ setLeftWidth, leftWidth, selectedPage, data }) => {
  const width = parseInt(leftWidth.split("%")[0]);
  const [isSkelton, setIsSkelton] = useState(true);
  const userDetails = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    let count = 0;
    const interval = setInterval(() => {
      count += 1;
      if (count == 3) {
        setIsSkelton(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <Row className={"parentRow"}>
        <Col xs={12} md={6} lg={width > 35 ? 7 : 12} className={"avatar-col"}>
          {isSkelton ? (
            <SkeletonTheme baseColor="#CDCDCD" height={56} width={56}>
              <div>
                <Skeleton />
              </div>
            </SkeletonTheme>
          ) : (
            <img
              className="headerProfileImg"
              src={logo}
              alt="img"
              width={60}
              height={60}
            />
          )}

          <div className="employee-name-div">
            {isSkelton ? (
              <SkeletonTheme baseColor="#CDCDCD" height={24} width={159}>
                <div>
                  <Skeleton />
                </div>
              </SkeletonTheme>
            ) : (
              <p className="employee-name-p">{userDetails?.fullname}</p>
            )}
            {/* {isSkelton ? (
              <SkeletonTheme baseColor="#CDCDCD" height={16} width={252}>
                <div>
                  <Skeleton />
                </div>
              </SkeletonTheme>
            ) : (
              <p className="employee-description-p">
                {`Employee - ${data?.employee?.department?.name}`}
              </p>
            )} */}
          </div>
        </Col>
        <Col
          xs={12}
          md={6}
          lg={width > 35 ? 5 : 12}
          className={
            isSkelton
              ? "icons-col-skelton"
              : width > 35
              ? "icons-col"
              : "icons-col-responsive"
          }
        >
          {isSkelton ? (
            <SkeletonTheme baseColor="#CDCDCD" height={44} width={44}>
              <div>
                <Skeleton />
              </div>
            </SkeletonTheme>
          ) : (
            <AlarmIcon />
          )}
          <div>
            {isSkelton ? (
              <SkeletonTheme baseColor="#CDCDCD" height={44} width={44}>
                <div>
                  <Skeleton />
                </div>
              </SkeletonTheme>
            ) : (
              <BellIcon />
            )}
          </div>
          <div>
            {isSkelton ? (
              <SkeletonTheme baseColor="#CDCDCD" height={44} width={44}>
                <div>
                  <Skeleton />
                </div>
              </SkeletonTheme>
            ) : (
              <CopyIcon />
            )}
          </div>
          <div>
            {isSkelton ? (
              <SkeletonTheme baseColor="#CDCDCD" height={44} width={44}>
                <div>
                  <Skeleton />
                </div>
              </SkeletonTheme>
            ) : (
              <QuestionIcon />
            )}
          </div>
          <div>
            {isSkelton ? (
              <SkeletonTheme baseColor="#CDCDCD" height={44} width={44}>
                <div>
                  <Skeleton />
                </div>
              </SkeletonTheme>
            ) : (
              <SearchIcon />
            )}
          </div>
          <div>
            {isSkelton ? (
              <SkeletonTheme baseColor="#CDCDCD" height={44} width={44}>
                <div>
                  <Skeleton />
                </div>
              </SkeletonTheme>
            ) : (
              <HeadPhoneIcon />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CustomHeader;
