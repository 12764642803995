import React from "react";

const OpenCloseSIderIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_258_19159)">
        <path
          d="M10.7778 3.44444L23 3.44445M10.7778 12L23 12M10.7778 20.5556H23M5.88889 12C5.88889 13.35 4.79447 14.4444 3.44444 14.4444C2.09441 14.4444 1 13.35 1 12C1 10.65 2.09441 9.55556 3.44444 9.55556C4.79447 9.55556 5.88889 10.65 5.88889 12ZM5.88889 3.44444C5.88889 4.79447 4.79447 5.88889 3.44444 5.88889C2.09441 5.88889 1 4.79447 1 3.44444C1 2.09441 2.09441 1 3.44444 1C4.79447 1 5.88889 2.09441 5.88889 3.44444ZM5.88889 20.5556C5.88889 21.9056 4.79447 23 3.44444 23C2.09441 23 1 21.9056 1 20.5556C1 19.2055 2.09441 18.1111 3.44444 18.1111C4.79447 18.1111 5.88889 19.2055 5.88889 20.5556Z"
          stroke="#3E6E4B"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_258_19159">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OpenCloseSIderIcon;
