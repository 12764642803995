import React from "react";
import Pdf from "../../assets/images/pdf.png";
import TrashIcon from "../../assets/svg/TrashIcon";
import DownloadIcon from "../../assets/svg/DownloadIcon";
import jsPDF from "jspdf";

const CustomerAttachment = ({ title, data }) => {
  const attachments = data?.fullData?.request?.filter(
    (item) => item.feild_type === "attachment"
  );

  // const downloadFileAsPDF = async (url, filename) => {
  //   try {
  //     const response = await fetch(url);
  //     const blob = await response.blob();

  //     if (filename.endsWith(".pdf")) {
  //       // If the file is already a PDF, download it directly
  //       const link = document.createElement("a");
  //       link.href = window.URL.createObjectURL(blob);
  //       link.setAttribute("download", filename);
  //       document.body.appendChild(link);
  //       link.click();
  //       link.parentNode.removeChild(link);
  //     } else {
  //       // If the file is not a PDF, convert it to a PDF and then download
  //       const reader = new FileReader();
  //       reader.onloadend = function () {
  //         const doc = new jsPDF();

  //         // Convert image to PDF
  //         if (filename.match(/\.(jpg|jpeg|png)$/)) {
  //           const imgData = reader.result;
  //           doc.addImage(imgData, "JPEG", 10, 10, 180, 160);
  //         } else {
  //           // Convert text or other formats to PDF
  //           doc.text(20, 20, reader.result);
  //         }

  //         doc.save(`${filename.split(".")[0]}.pdf`);
  //       };

  //       // Read file as Data URL for images or as text for other formats
  //       if (filename.match(/\.(jpg|jpeg|png)$/)) {
  //         reader.readAsDataURL(blob);
  //       } else {
  //         reader.readAsText(blob);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error downloading or converting file:", error);
  //   }
  // };

  const downloadFileAsPDF = async (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.focus();
    } else {
      console.error("Failed to open new tab/window.");
    }
  };

  return (
    <>
      <div>
        <p className="employeeAttachment">{title}</p>
        {attachments?.map((attachment, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              gap: "10px",
              marginBottom: "10px",
              cursor: "pointer",
              height:"30%"
            }}
            className="cardBgColor"
            onClick={() =>
              downloadFileAsPDF(
                `${data?.fullData?.service_request_dir}${attachment.value.file}`,
                attachment.value.name
              )
            }
          >
            <div className="pdfImgLogo">
              <img
                src={Pdf}
                alt="img tumer"
                className="imgPlant"
                style={{ width: "60px", height: "80px" }}
              />
            </div>
            <div
              style={{ display: "flex", flexDirection: "column" }}
              className="store"
            >
              <p className="plantName">{attachment.value.name}</p>
              {/* <div
                className="EmpAttachIcon"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  downloadFileAsPDF(
                    `${data?.fullData?.service_request_dir}${attachment.value.file}`,
                    attachment.value.name
                  )
                }
              >
                
                <DownloadIcon width={50} height={50} />
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

// const CustomerAttachment = ({ title, data }) => {
//   // console.log("🚀 ~ CustomerAttachment ~ data:", data);

//   const handleGeneratePDF = async () => {
//     const pdfDoc = await PDFDocument.create();
//     let page = pdfDoc.addPage([600, 800]);
//     const { width, height } = page.getSize();
//     const font = await pdfDoc.embedFont("Helvetica");
//     let yPosition = height - 50;

//     // Add title
//     page.drawText(title, {
//       x: 50,
//       y: yPosition,
//       size: 18,
//       font,
//       color: rgb(0, 0, 0),
//     });

//     yPosition -= 40;

//     // Add each request item with field_type "file"
//     for (const item of data?.fullData?.request || []) {
//       if (item.feild_type === "attachment") {
//         try {
//           const byteArray = Uint8Array.from(atob(item.value), c => c.charCodeAt(0));
//           const embeddedPdf = await PDFDocument.load(byteArray);
//           const [embeddedPage] = await pdfDoc.copyPages(embeddedPdf, [0]);

//           // If there is not enough space for the next page, add a new page
//           if (yPosition - embeddedPage.getHeight() < 50) {
//             page = pdfDoc.addPage([600, 800]);
//             yPosition = height - 50;
//           }

//           page.drawPage(embeddedPage, { x: 0, y: yPosition - embeddedPage.getHeight() });
//           yPosition -= embeddedPage.getHeight() + 20;
//         } catch (error) {
//           console.error("Error embedding PDF:", error);
//         }
//       }
//     }

//     const pdfBytes = await pdfDoc.save();
//     const blob = new Blob([pdfBytes], { type: "application/pdf" });
//     saveAs(blob, `${title}.pdf`);
//   };

//   return (
//     <>
//       <div>
//         <p className="employeeAttachment">{title}</p>
//         <div style={{ display: "flex", gap: "10px" }} className="cardBgColor">
//           <div className="pdfImgLogo">
//             <img src={Pdf} alt="img tumer" className="imgPlant" />
//           </div>
//           <div
//             style={{ display: "flex", flexDirection: "column" }}
//             className="store"
//           >
//             {/* <p className="plantName">Reason of return</p> */}
//             <div className="EmpAttachIcon">
//               <div>
//                 <DownloadIcon width={50} height={50} />
//               </div>
//               {/* <TrashIcon width={50} height={50} /> */}
//             </div>
//           </div>
//           <div
//             style={{
//               color: "#535353",
//               display: "flex",
//               alignItems: "center",
//               marginLeft: "20px",
//             }}
//           >
//             {/* <p>357 MB</p> */}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

export default CustomerAttachment;
