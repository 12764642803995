import { Col, Row } from "react-bootstrap";
import { TbWorld } from "react-icons/tb";
import { useContext, useState } from "react";
import "./style.css";
import "./style.ar.css";
import { LoginCard } from "../../components/LoginCard";
import logo from "../../assets/images/logo.png";
import loginBg from "../../assets/images/loginBg.png";
import { Loading } from "../Loading";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Row className="login-container">
          <Col xs={12} md={1} lg={1}></Col>
          <Col className="logo-col " xs={12} md={3} lg={3}>
            <img className="logo" src={logo} />
            <p className="company-name-div">Al Dhaid Municipality</p>
            <p className="employees-portal-div">Employee Portal</p>
          </Col>
          <Col xs={12} md={8} lg={8} style={{ padding: "0px " }}>
            <div
              className="login-card-col"
              style={{
                background: `url(${loginBg})`,
                backgroundSize: "cover",
                backgroundRepeat: "round",
              }}
            >
              <div>
                <div className="login-card-div">
                  <LoginCard setIsLoading={setIsLoading} />
                </div>
                <div className="language-div">
                  <p className="language-p">العربية</p>
                  <TbWorld color="green" size={22} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Login;
