import React from "react";

const AttentionIcon = ({ width, height }) => {
  return (
    <svg
      style={{ marginBottom: "15px" }}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 96 96"
      fill="none"
    >
      <path
        d="M38 48H46.1977C48.524 48 49.8393 51.1764 48.5109 53.0861C45.3866 57.5779 43 63.3029 43 68C43 75.5 53 78 58 68M46.1238 26.1247H49.8738M46.1238 29.8747H49.8738M93 48C93 72.8528 72.8528 93 48 93H6.62065C4.87152 93 3.66309 91.2499 4.28282 89.6142L8.75104 77.8212C9.89716 74.7963 9.44132 71.4373 7.96125 68.5609C4.79007 62.398 3 55.408 3 48C3 23.1472 23.1472 3 48 3C72.8528 3 93 23.1472 93 48ZM53 28C53 30.7614 50.7614 33 48 33C45.2386 33 43 30.7614 43 28C43 25.2386 45.2386 23 48 23C50.7614 23 53 25.2386 53 28Z"
        stroke="#E0BE08"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default AttentionIcon;
