function ProgressIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M8.84771 2.93413C8.84771 1.86594 9.67488 1 10.6953 1H13.3047C14.3251 1 15.1522 1.86594 15.1522 2.93414C15.1522 4.42304 16.6919 5.35359 17.9236 4.60914C18.8073 4.07503 19.9373 4.39198 20.4475 5.31709L21.7522 7.68277C22.2624 8.60787 21.9596 9.79079 21.0759 10.3249C19.8442 11.0694 19.8442 12.9305 21.0759 13.675C21.9596 14.2091 22.2624 15.392 21.7522 16.3171L20.4475 18.6828C19.9373 19.6079 18.8073 19.9248 17.9236 19.3907C16.6919 18.6463 15.1522 19.5769 15.1522 21.0658C15.1522 22.134 14.325 23 13.3046 23H10.6953C9.6749 23 8.84771 22.134 8.84771 21.0658C8.84771 19.5769 7.30807 18.6463 6.07636 19.3907C5.19268 19.9248 4.06273 19.6079 3.55253 18.6828L2.24784 16.3171C1.73764 15.392 2.04041 14.2091 2.9241 13.675C4.15582 12.9305 4.15582 11.0693 2.9241 10.3249C2.04042 9.79078 1.73764 8.60787 2.24784 7.68277L3.55254 5.31706C4.06273 4.39197 5.19269 4.07502 6.07637 4.60912C7.30806 5.35357 8.84771 4.42302 8.84771 2.93413Z"
        stroke="#E0BE08"
        strokeWidth="2"
      />
      <path
        d="M16.203 12C16.203 14.4301 14.3212 16.4 12 16.4C9.67871 16.4 7.79695 14.4301 7.79695 12C7.79695 9.56995 9.67871 7.6 12 7.6C14.3212 7.6 16.203 9.56995 16.203 12Z"
        stroke="#E0BE08"
        strokeWidth="2"
      />
    </svg>
  );
}

export default ProgressIcon;
