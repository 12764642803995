function StarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.6364 6.82786L13.0989 2.59212C12.6277 1.80565 11.4459 1.80188 10.9693 2.58534L8.29167 6.98705C7.93702 7.57007 7.33898 7.97953 6.64878 8.1119L2.99011 8.81358C2.04253 8.99532 1.6733 10.1056 2.33623 10.7798L4.98246 13.4712C5.54832 14.0467 5.77584 14.8558 5.5884 15.6261L4.39063 20.5486C4.14441 21.5605 5.28382 22.3614 6.21601 21.8317L10.7857 19.2349C11.5592 18.7953 12.5211 18.7953 13.2946 19.2349L17.8644 21.8317C18.7966 22.3614 19.936 21.5605 19.6897 20.5486L18.4815 15.583C18.2999 14.8366 18.5076 14.0521 19.0387 13.4792L21.6882 10.6209C22.3194 9.93988 21.9416 8.85888 21.0099 8.68019L17.2875 7.96627C16.5917 7.83283 15.9898 7.41786 15.6364 6.82786Z"
        stroke="#6E4C3E"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default StarIcon;
