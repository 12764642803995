import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import RequestedPlants from "../RequestedPlants/RequestedPlants";
import "./style.css";
import CustomModal from "../Modal/Modal";
import EmployeeAttachment from "../EmployeeAttachment/EmployeeAttachment";
import CustomerAttachment from "../CustomerAttachment/CustomerAttachment";
import RequestTabForm from "../RequestTabForm/RequestTabForm";
import { Loading } from "../../pages/Loading";
import arrow from "../../assets/icons/arrow.png";
import close from "../../assets/icons/close.png";
import TabComponent from "../Tab/Tab";
import { Button } from "../Button";
import EmployeeNote from "../EmployeeNote/EmployeeNote";
import DragFiles from "../DragFiles/DragFiles";
import { Select, DatePicker, Avatar, TimePicker } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import CustomTable from "../Table/Table";
import Swal from "sweetalert2";

const RightSideBar = ({
  isSkelton,
  setLeftWidth,
  leftWidth,
  selectedPage,
  isAdmin,
  data,
  employees,
  setSelectedEmployee,
  handleAssignRequest,
  handleAppointmentDate,
  RefetchRequestList,
  handleAppointment,
  AppointmentDate,
  setRefetchData,
  SelectedEmployee,
  setUpdatedData,
}) => {
  const employeeAssigned =
    data?.fullData?.employee_id && data?.fullData?.employee_id !== "0"
      ? data?.fullData?.employee_id
      : "";
  const user = JSON.parse(sessionStorage.getItem("user"));
  const token = sessionStorage.getItem("token");
  const { Option } = Select;
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("request_details");
  const [selectedTab2, setSelectedTab2] = useState("progress_log");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [EmployeesNote, setEmployeesNote] = useState();
  const [extractedPlants, setExtractedPlants] = useState([]);

  // const openModal = () => {
  //   setModalOpen(true);
  // };

  useEffect(() => {
    setEmployeesNote(data?.fullData?.employee_notes);
  }, [data]);

  const closeModal = () => {
    setModalOpen(false);
  };
  const handleTabSelect = (selectedKey) => {
    setSelectedTab(selectedKey);
  };
  const handleTabSelect2 = (selectedKey) => {
    setSelectedTab2(selectedKey);
  };
  const handleEmployeeSelect = (value) => {
    setSelectedEmployee(value);
  };

  // const handleTimeChange = (time, timeString) => {
  //   console.log(time, timeString);
  // };

  // const disablePastDates = (current) => {
  //   return current && current <= moment().endOf('day');
  // };
  const disablePastDates = (current) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to the start of today
    const tomorrow = new Date(today);
    // tomorrow.setDate(tomorrow.getDate() + 1); // Set to tomorrow

    // Convert current date to start of day for comparison
    const currentDate = new Date(current);
    currentDate.setHours(0, 0, 0, 0);

    return currentDate < tomorrow;
  };

  const initialDate = data?.fullData?.appointment_date
    ? dayjs(data.fullData.appointment_date)
    : null;

  // const TableData = [data?.fullData];
  const TableAssignedData = data?.fullData?.request_logs?.filter(
    (log) => log.action_on === "AssignToEmployee"
  );
  const TableAppointmentData = data?.fullData?.request_logs?.filter(
    (log) => log.action_on === "UpdateAppointment"
  );
  const TableNotesData = data?.fullData?.request_logs?.filter(
    (log) => log.action_on === "EMP_NOTES"
  );
  const columns = [
    // {
    //   // title: "",
    //   dataIndex: "",
    //   width: 4,
    //   render: (text) => "",
    // },
    {
      title: "ID",
      dataIndex: "employee_id",
      width: 4,
      render: (text, record) => (
        <span style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}>
          {text}
        </span>
      ),
    },
    {
      title: "Employee name",
      dataIndex: "fullname",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            wordWrap: "break-word",
          }}
        >
          <Avatar shape="square" size="large" style={{ marginRight: "10px" }} />
          <span>{record?.employee?.fullname}</span>
        </div>
      ),
    },
    {
      title: "Created Date & Time",
      dataIndex: "created",
      render: (text, record) => {
        return <span>{text}</span>;
      },
    },
    // {
    //   // title: "",
    //   dataIndex: "",
    //   width: 4,
    //   render: (text) => "",
    // },
  ];
  const AssignedColumns = [
    // {
    //   // title: "",
    //   dataIndex: "",
    //   width: 4,
    //   render: (text) => "",
    // },
    {
      title: "ID",
      dataIndex: "id",
      width: 4,
      render: (text, record) => (
        <span style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}>
          {text}
        </span>
      ),
    },
    {
      title: "Employee name",
      dataIndex: "fullname",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            wordWrap: "break-word",
          }}
        >
          <Avatar shape="square" size="large" style={{ marginRight: "10px" }} />
          <span>{record?.employee?.fullname}</span>
        </div>
      ),
    },
    {
      title: "Created Date & Time",
      dataIndex: "created",
      render: (text, record) => {
        return <span>{text}</span>;
      },
    },
    // {
    //   // title: "",
    //   dataIndex: "",
    //   width: 4,
    //   render: (text) => "",
    // },
  ];
  const columnsNotes = [
    {
      // title: "",
      dataIndex: "",
      width: 4,
      render: (text) => "",
    },
    {
      title: "ID",
      dataIndex: "employee_id",
      width: 4,
      render: (text, record) => (
        <span style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}>
          {text}
        </span>
      ),
    },
    {
      title: "Employee name",
      dataIndex: "fullname",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            wordWrap: "break-word",
          }}
        >
          <Avatar shape="square" size="large" style={{ marginRight: "10px" }} />
          <span>{record?.employee?.fullname}</span>
        </div>
      ),
    },
    {
      title: "Notes",
      dataIndex: "employee_notes",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            wordWrap: "break-word",
          }}
        >
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "created",
      render: (text, record) => {
        return <span>{text}</span>;
      },
    },
    {
      // title: "",
      dataIndex: "",
      width: 4,
      render: (text) => "",
    },
  ];
  const columnsApprovelLogs = [
    // {
    //   // title: "",
    //   dataIndex: "",
    //   width: 4,
    //   render: (text) => "",
    // },
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   width: 4,
    //   render: (text, record) => (
    //     <span style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}>
    //       {record?.EmployeeAppointment?.id}
    //     </span>
    //   ),
    // },
    {
      title: "Employee Name",
      width: 100,
      dataIndex: "approval_status",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            wordWrap: "break-word",
          }}
        >
          {/* <Avatar shape="square" size="large" style={{ marginRight: "10px" }} /> */}
          <span>{record?.employee?.fullname}</span>
        </div>
      ),
    },
    {
      title: "Request Status",
      width: 100,
      dataIndex: "approval_status",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            wordWrap: "break-word",
          }}
        >
          {/* <Avatar shape="square" size="large" style={{ marginRight: "10px" }} /> */}
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Employee Note",
      width: 100,
      dataIndex: "employee_notes",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            wordWrap: "break-word",
          }}
        >
          {/* <Avatar shape="square" size="large" style={{ marginRight: "10px" }} /> */}
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Date & Time",
      width: 500,
      dataIndex: "modified",
      render: (text, record) => (
        <div className="avatar-name-container">
          {/* <Avatar shape="square" size="large" style={{ marginRight: "10px" }} /> */}
          <span>{text}</span>
        </div>
      ),
    },

    // {
    //   // title: "",
    //   dataIndex: "",
    //   width: 4,
    //   render: (text) => "",
    // },
  ];

  const tabs = [
    {
      event_key: "request_details",
      title: "Request Details",
      component: (
        <RequestTabForm
          data={data}
          request="Request No"
          requestDate="Request Date"
          service="Service name"
          requestValue="Requests"
        />
      ),
    },
    {
      event_key: "requester_details",
      title: "Requester Details",
      component: (
        <RequestTabForm
          data={data}
          requesterName="Requester Name"
          emirateID="Requester Emirates ID"
          mobileNo="Mobile number"
          email="Email address"
          address="Address"
        />
      ),
    },
    {
      event_key: "attachment_details",
      title: "Request Attachments",
      component: (
        <div style={{ background: "#DAF4E1", padding: "10px" }}>
          <CustomerAttachment title={"Customer Attachment"} data={data} />
        </div>
      ),
    },
  ];

  const tabs2 = [
    {
      event_key: "progress_log",
      title: "Progress Log",
      component: (
        // <RequestTabForm
        //   data={data}
        //   request="Request No"
        //   requestDate="Request Date"
        //   service="Service name"
        // />
        <div>
          <CustomTable
            pagination={false}
            data={data?.fullData?.approvals}
            columns={columnsApprovelLogs}
          />
        </div>
      ),
    },
    {
      event_key: "progress_flow",
      title: "Progress Flow",
      component: (
        <RequestTabForm
          data={data}
          requesterName="Requester Name"
          emirateID="Requester Emirates ID"
          mobileNo="Mobile number"
          email="Email address"
          address="Address"
        />
      ),
    },
  ];

  const width = parseInt(leftWidth?.split("%")[0]);
  const rightWidth = 100 - width;
  const newWidth = 392 + rightWidth;

  const handleEmployeeNotes = async () => {
    setIsLoading(true);
    if (data) {
      const body = {
        employee_id: user?.id,
        request_id: data?.number,
        department_id: user?.department_id,
        employee_notes: EmployeesNote,
      };
      axios
        .post(
          "https://appadmin.buyinpk.com/portalapis/ServiceRequests/saveNotes",
          body
        )
        .then((res) => {
          // console.log(res?.data);
          setIsLoading(false);
          RefetchRequestList();
          RefetchRequestList();
          setIsLoading(false);
          setUpdatedData("");
          setRefetchData((prev) => !prev);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "success",
            title: "Notes Saved",
          });
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          // alert("something went wrong");
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: "something went wrong",
          });
        });
    }
  };

  const handleRequestReject = () => {
    // setIsLoading(true);
    if (data) {
      const formData = new FormData();
      formData.append("request_id", data?.number);
      formData.append("employee_id", user?.id);
      formData.append("status", "REJECTED");
      formData.append("employee_notes", EmployeesNote);
      // selectedFile?.length > 0 &&
      //   formData.append("filename", selectedFile[0]?.name);
      // selectedFile?.length > 0 && formData.append("filetype", "pdf");
      // selectedFile?.length > 0 &&
      //   formData.append("employee_attachment", selectedFile[0]);
      // formData.append("delivery_date", "22-33-2222");
      // let body = {
      //   request_id: Number(data?.number),
      //   employee_id: Number(user?.id),
      //   status: "REJECTED",
      //   employee_notes: EmployeesNote,
      //   filetype: "pdf",
      // };
      axios
        .post(
          "https://appadmin.buyinpk.com/portalapis/ServiceRequests/reject",
          formData
        )
        .then((res) => {
          // console.log(res?.data);
          Swal.fire({
            title: "Are you sure you want to reject this ?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: "No",
            customClass: {
              actions: "my-actions",
              cancelButton: "order-1 right-gap",
              confirmButton: "order-2",
              denyButton: "order-3",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              RefetchRequestList();
              setIsLoading(false);
              // alert(res?.data?.msg);
              setRefetchData((prev) => !prev);
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.onmouseenter = Swal.stopTimer;
                  toast.onmouseleave = Swal.resumeTimer;
                },
              });
              Toast.fire({
                // icon: "success",
                title: res?.data?.msg,
              });
            } else if (result.isDenied) {
              // Swal.fire("Changes are not saved", "", "info");
            }
          });
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          // alert("something went wrong");
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: "something went wrong",
          });
        });
    }
  };
  const handleEmployeeAttachmentDelete = (id) => {
    if (id) {
      // setIsLoading(true);
      const formData = new FormData();
      formData.append("request_id", data?.number);
      formData.append("employee_id", user?.id);
      formData.append("token", token);
      formData.append("fileid", id);

      axios
        .post(
          "https://appadmin.buyinpk.com/portalapis/ServiceRequests/deleteRquestAttachments",
          formData
        )
        .then((res) => {
          // console.log(res?.data);
          Swal.fire({
            title: "Are you Sure you want to delete this file?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: "No",
            customClass: {
              actions: "my-actions",
              cancelButton: "order-1 right-gap",
              confirmButton: "order-2",
              denyButton: "order-3",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              // console.log(res?.data);
              RefetchRequestList();
              setIsLoading(false);
              setUpdatedData("");
              // alert(res?.data?.msg);
              setRefetchData((prev) => !prev);
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.onmouseenter = Swal.stopTimer;
                  toast.onmouseleave = Swal.resumeTimer;
                },
              });
              Toast.fire({
                // icon: "success",
                title: res?.data?.msg,
              });
            } else if (result.isDenied) {
              // Swal.fire("Changes are not saved", "", "info");
            }
          });
        })
        // .then((res) => {

        //   const Toast = Swal.mixin({
        //     toast: true,
        //     position: "top-end",
        //     showConfirmButton: false,
        //     timer: 3000,
        //     timerProgressBar: true,
        //     didOpen: (toast) => {
        //       toast.onmouseenter = Swal.stopTimer;
        //       toast.onmouseleave = Swal.resumeTimer;
        //     },
        //   });
        // })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          // alert("something went wrong");
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: "something went wrong",
          });
        });
    }
  };
  useEffect(() => {
    if (selectedFile?.length > 0) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("request_id", data?.number);
      formData.append("employee_id", user?.id);
      formData.append("token", token);
      formData.append("attachment", selectedFile[0]);

      axios
        .post(
          "https://appadmin.buyinpk.com/portalapis/ServiceRequests/uploadRquestAttachments",
          formData
        )
        .then((res) => {
          // console.log(res?.data);
          RefetchRequestList();
          setIsLoading(false);
          setUpdatedData("");
          // alert(res?.data?.msg);
          setRefetchData((prev) => !prev);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          // alert("something went wrong");
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: "something went wrong",
          });
        });
    }
  }, [selectedFile]);

  return (
    <>
      <Col
        className={"right-side-bar-container"}
        style={{ height: width < 70 ? "" : "100vh", paddingBottom: "20px" }}
      >
        {selectedPage == "requests"
          ? !isAdmin && (
              <Row>
                <Button
                  className={width < 70 ? "reply-button" : "reply-button-small"}
                  title={width < 70 ? "Reply" : <img src={arrow} />}
                  onClick={() => {
                    setModalOpen(true);
                  }}
                />
              </Row>
            )
          : !isAdmin && (
              <Row
                style={{
                  display: width < 70 ? "" : "flex",
                  flexDirection: width < 70 ? "row" : "column",
                  gap: width < 70 ? "" : "16px",
                  position: "fixed",
                  top: 0,
                  zIndex: 100,
                  marginTop: "20px",
                }}
              >
                {/* {user &&
                  (user.designation_id === "2" ||
                    user.designation_id === "1") &&
                  data?.status === "IN_PROGRES" && (
                    <>
                      <Col lg={6}>
                        {isSkelton ? (
                          <SkeletonTheme
                            baseColor="#CDCDCD"
                            height={52}
                            width={188}
                          >
                            <div>
                              <Skeleton />
                            </div>
                          </SkeletonTheme>
                        ) : (
                          <Button
                            className={
                              width < 70 ? "send-button" : "send-button-small"
                            }
                            title={width < 70 ? "Approve" : <img src={arrow} />}
                            onClick={() => {
                              setModalOpen(true);
                            }}
                          />
                        )}
                      </Col>
                      <Col lg={6}>
                        {isSkelton ? (
                          <SkeletonTheme
                            baseColor="#CDCDCD"
                            height={52}
                            width={188}
                          >
                            <div>
                              <Skeleton />
                            </div>
                          </SkeletonTheme>
                        ) : (
                          <Button
                            className={
                              width < 70
                                ? "reject-button"
                                : "reject-button-small"
                            }
                            title={width < 70 ? "Reject" : <img src={close} />}
                            onClick={handleRequestReject}
                          />
                        )}
                      </Col>
                    </>
                  )} */}

                {((data?.status === "IN_PROGRES" &&
                  (user?.designation_id === "1" ||
                    user?.designation_id === "2")) ||
                  data?.status === "PENDING") && (
                  <>
                    <Col lg={6}>
                      {isSkelton ? (
                        <SkeletonTheme
                          baseColor="#CDCDCD"
                          height={52}
                          width={188}
                        >
                          <div>
                            <Skeleton />
                          </div>
                        </SkeletonTheme>
                      ) : (
                        <Button
                          className={
                            width < 70 ? "send-button" : "send-button-small"
                          }
                          title={width < 70 ? "Approve" : <img src={arrow} />}
                          onClick={() => {
                            setModalOpen(true);
                          }}
                        />
                      )}
                    </Col>
                    <Col lg={6}>
                      {isSkelton ? (
                        <SkeletonTheme
                          baseColor="#CDCDCD"
                          height={52}
                          width={188}
                        >
                          <div>
                            <Skeleton />
                          </div>
                        </SkeletonTheme>
                      ) : (
                        <Button
                          className={
                            width < 70 ? "reject-button" : "reject-button-small"
                          }
                          title={width < 70 ? "Reject" : <img src={close} />}
                          onClick={handleRequestReject}
                        />
                      )}
                    </Col>
                  </>
                )}
              </Row>
            )}

        {/* Tabs Section Start */}
        <Row style={{ marginTop: "100px" }}>
          <Col sm={12}>
            {isSkelton ? (
              <SkeletonTheme baseColor="#CDCDCD" width={392} height={301}>
                <div>
                  <Skeleton />
                </div>
              </SkeletonTheme>
            ) : width < 70 ? (
              !isAdmin && (
                <div
                  style={{
                    height: "301px",
                    width: width >= 65 ? "392px" : `100%`,
                    overflowY: "scroll",
                    overflowX: "hidden",
                    paddingLeft: width >= 65 ? "0px" : "50px",
                    marginRight: width >= 65 ? "0px" : "20px",
                  }}
                >
                  <TabComponent
                    selectedTab={selectedTab}
                    handleTabSelect={handleTabSelect}
                    tabs={tabs}
                  />
                </div>
              )
            ) : (
              ""
            )}
          </Col>
        </Row>
        {/* Tabs Section End */}
        <>
          {((data?.status === "IN_PROGRES" &&
            (user?.designation_id === "1" || user?.designation_id === "2")) ||
            data?.status === "PENDING") && (
            <>
              {!isAdmin && (
                <Row style={{ width: "85%" }}>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "8px",
                        marginLeft: 0,
                      }}
                    >
                      <p className="requestPlants">Assign Request</p>
                      <button
                        className="assign-button"
                        onClick={handleAssignRequest}
                      >
                        Assign
                      </button>
                    </div>
                    <Select
                      style={{
                        width: "100%",
                        height: "42px",
                        background: "#F6F6F6",
                        fontSize: "16px",
                        fontWeight: 600,
                        lineHeight: "normal",
                        border: "none",
                      }}
                      value={employeeAssigned || SelectedEmployee}
                      placeholder="Assign Request"
                      onChange={handleEmployeeSelect}
                    >
                      {user &&
                      (user.designation_id === "2" ||
                        user.designation_id === "1") ? (
                        <>
                          {employees?.data?.map(
                            (employee, i) =>
                              employee?.employee?.fullname && (
                                <Option
                                  style={{ fontWeight: 600 }}
                                  key={i}
                                  value={employee?.employee?.id}
                                >
                                  {employee?.employee?.fullname}
                                </Option>
                              )
                          )}
                        </>
                      ) : (
                        user && (
                          <Option style={{ fontWeight: 600 }} value={user?.id}>
                            Assign to Myself
                          </Option>
                        )
                      )}
                    </Select>
                  </Col>
                </Row>
              )}

              {data?.fullData && (
                <>
                  {data?.fullData?.request_logs?.some(
                    (log) => log.action_on === "AssignToEmployee"
                  ) && (
                    <div>
                      <p
                        className="requestPlants"
                        style={{ margin: "0 0 10px 0" }}
                      >
                        Assigning Detail
                      </p>
                      <CustomTable
                        pagination={false}
                        data={TableAssignedData}
                        columns={AssignedColumns}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {((data?.status === "IN_PROGRES" &&
            (user?.designation_id === "1" || user?.designation_id === "2")) ||
            data?.status === "PENDING") && (
            <>
              {!isAdmin && (
                <Row style={{ width: "85%" }}>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "8px",
                        marginLeft: 0,
                      }}
                    >
                      <p className="requestPlants">Appointment Date & Time</p>
                      <button
                        onClick={handleAppointment}
                        className="assign-button"
                      >
                        Save
                      </button>
                    </div>
                    <DatePicker
                      showTime={{
                        format: "HH:mm",
                        defaultValue: dayjs("00:00", "HH:mm"),
                      }}
                      value={
                        AppointmentDate ? dayjs(AppointmentDate) : initialDate
                      }
                      style={{
                        width: "100%",
                        height: "42px",
                        background: "#F6F6F6",
                        fontSize: "16px",
                        fontWeight: 600,
                        lineHeight: "normal",
                        border: "none",
                      }}
                      onChange={handleAppointmentDate}
                      disabledDate={disablePastDates}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </>

        {data?.fullData &&
          data?.fullData?.request_logs?.some(
            (log) => log.action_on === "UpdateAppointment"
          ) && (
            <div>
              <p className="requestPlants" style={{ margin: "0 0 10px 0" }}>
                Appointment Detail
              </p>
              <CustomTable
                pagination={false}
                data={TableAppointmentData}
                columns={columns}
              />
            </div>
          )}
        {/* Plants Section Start */}
        {!isAdmin && (
          <Row
            style={{ width: "100%", paddingLeft: isSkelton ? "30px" : "25px" }}
          >
            <Col>
              {isSkelton ? (
                <SkeletonTheme baseColor="#CDCDCD" height={138} width={392}>
                  <div>
                    <Skeleton />
                  </div>
                </SkeletonTheme>
              ) : width < 70 ? (
                // <div style={{ width: width >= 35 ? "auto" : `100%` }}>
                data?.fullData?.plants.length > 0 && (
                  <RequestedPlants
                    width={width}
                    data={data}
                    setExtractedPlants={setExtractedPlants}
                  />
                )
              ) : (
                // </div>
                ""
              )}
            </Col>
          </Row>
        )}
        {/* Plants Section End */}

        {/* Employee Notes Section Start */}
        <Row style={{ width: "85%" }}>
          {width < 70
            ? !isAdmin && (
                <>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "8px",
                      marginLeft: 0,
                    }}
                  >
                    <p className="requestPlants"> Employee notes</p>
                    {((data?.status === "IN_PROGRES" &&
                      (user?.designation_id === "1" ||
                        user?.designation_id === "2")) ||
                      data?.status === "PENDING") && (
                      <button
                        onClick={handleEmployeeNotes}
                        className="assign-button"
                      >
                        Save
                      </button>
                    )}
                  </div>
                  <EmployeeNote
                    EmployeesNote={EmployeesNote}
                    setEmployeesNote={setEmployeesNote}
                    data={data}
                  />
                </>
              )
            : ""}
        </Row>
        {/* Employee Notes Section End */}
        {data?.fullData &&
          data?.fullData?.request_logs?.some(
            (log) => log.action_on === "EMP_NOTES"
          ) && (
            <CustomTable
              pagination={false}
              data={TableNotesData}
              columns={columnsNotes}
            />
          )}

        {/* Employee Attachment Start */}
        {/* {data?.status !== "APPROVED" && data?.status !== "REJECTED" && data && (
        !isAdmin && (
          <Row
            style={{
              width: "100%",
              paddingLeft: isSkelton ? "30px" : "25px",
              paddingRight: "25px",
            }}
          >
            <Col>
              {isSkelton ? (
                <SkeletonTheme baseColor="#CDCDCD" height={301} width={392}>
                  <div>
                    <Skeleton />
                  </div>
                </SkeletonTheme>
              ) :
               width < 70 ? (
                <div style={{ width: "100%" }}>
                  <EmployeeAttachment
                    title={"Employee Attachment"}
                    selectedFile={selectedFile}
                    setselectedFile={setselectedFile}
                  />
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
        ))}
      
        <Row>
          {width < 70
            ? !isAdmin && (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "60px",
                    paddingRight: "60px",
                    marginTop: "20px",
                  }}
                >
                  <DragFiles setselectedFile={setselectedFile} />
                </div>
              )
            : ""}
        </Row>*/}
        {/* Uncomment and use Row and Col if needed for layout */}
        {/* !isAdmin && (
              <Row
                style={{
                  width: "100%",
                  paddingLeft: isSkeleton ? "30px" : "25px",
                  paddingRight: "25px",
                }}
              >
              <Col>
                  {isSkeleton ? (
                    <SkeletonTheme baseColor="#CDCDCD" height={301} width={392}>
                    <div>
                    <Skeleton />
                    </div>
                    </SkeletonTheme>
                    ) : */}
        {width < 70 ? (
          <div style={{ width: "85%" }}>
            <EmployeeAttachment
              title={"Employee Attachment"}
              selectedFile={selectedFile}
              files={data}
              setSelectedFile={setSelectedFile}
              handleEmployeeAttachmentDelete={handleEmployeeAttachmentDelete}
            />
          </div>
        ) : (
          ""
        )}
        {/* Uncomment and use Row and Col if needed for layout */}
        {/* </Col>
              </Row> */}
        {((data?.status === "IN_PROGRES" &&
          (user?.designation_id === "1" || user?.designation_id === "2")) ||
          data?.status === "PENDING") && (
          <Row>
            {width < 70 && !isAdmin && (
              <div
                style={{
                  width: "100%",
                  paddingLeft: "60px",
                  paddingRight: "60px",
                  marginTop: "20px",
                }}
              >
                <DragFiles setSelectedFile={setSelectedFile} />
              </div>
            )}
          </Row>
        )}

        <Row style={{ marginTop: "10px" }}>
          <Col sm={12}>
            {width < 70
              ? !isAdmin && (
                  <div
                    style={{
                      height: "301px",
                      width: width >= 65 ? "392px" : `100%`,
                      overflowY: "scroll",
                      overflowX: "hidden",
                      paddingLeft: width >= 65 ? "0px" : "50px",
                      marginRight: width >= 65 ? "0px" : "20px",
                    }}
                  >
                    <TabComponent
                      data={data}
                      selectedTab={selectedTab2}
                      handleTabSelect={handleTabSelect2}
                      tabs={tabs2}
                    />
                  </div>
                )
              : ""}
          </Col>
        </Row>

        {/* Tabs Section End */}
      </Col>
      {isModalOpen && (
        <CustomModal
          data={data}
          isOpen={isModalOpen}
          onClose={closeModal}
          setIsLoading={setIsLoading}
          RefetchRequestList={RefetchRequestList}
          setRefetchData={setRefetchData}
          selectedFile={selectedFile}
          EmployeesNote={EmployeesNote}
          fetchRequestData={RefetchRequestList}
          extractedPlants={extractedPlants}
          setUpdatedData={setUpdatedData}
        />
      )}
      {isLoading && (
        <div
          style={{
            background: "rgb(99 99 99 / 9%);",
            // background: "red",
            position: "fixed",
            top: "0%",
            width: "100%",
            right: "0",
          }}
        >
          <Loading />
        </div>
      )}
    </>
  );
};

export default RightSideBar;
