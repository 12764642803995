import { Col, Row } from "react-bootstrap";
import "./style.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Card = ({
  icon,
  title,
  count,
  color,
  isSkelton,
  menu,
  isAdmin,
  setListStatus,
  rowData,
}) => {
  return (
    <Col
      className="card-col"
      style={{ cursor: "pointer" }}
      onClick={() => {
        setListStatus(title);
        rowData("");
      }}
    >
      <Row>
        {isSkelton ? (
          <SkeletonTheme baseColor="#CDCDCD" height={24} width={24}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
        ) : (
          <div style={{ display: "flex", justifyContent: "" }}>
            <div className="iconStyles">{icon}</div>
            {menu && (
              <div style={{ marginLeft: "80px" }} className="iconStyles">
                {menu}
              </div>
            )}
          </div>
        )}
      </Row>
      <Row>
        {isSkelton ? (
          <SkeletonTheme baseColor="#CDCDCD" height={24} width={157}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
        ) : (
          <p className="card-title-p" style={{ color: `${color}` }}>
            {title}
          </p>
        )}
      </Row>
      <Row>
        {isSkelton ? (
          <SkeletonTheme baseColor="#CDCDCD" height={48} width={73}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
        ) : (
          <p className="card-count-p" style={{ color: `${color}` }}>
            {count}
          </p>
        )}
      </Row>
    </Col>
  );
};

export default Card;
