import React from "react";

const CardMenuIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_331_10182)">
        <path
          d="M12.5626 11.484L12.5626 12.5153M11.4376 11.484L11.4376 12.5153M12.5626 1.85904L12.5626 2.89029M11.4376 1.85904L11.4376 2.89029M12.5626 21.109L12.5626 22.1403M11.4376 21.109L11.4376 22.1403M12 13.375C11.1716 13.375 10.5 12.7594 10.5 12C10.5 11.2406 11.1716 10.625 12 10.625C12.8284 10.625 13.5 11.2406 13.5 12C13.5 12.7594 12.8284 13.375 12 13.375ZM12 3.75C11.1716 3.75 10.5 3.13439 10.5 2.375C10.5 1.61561 11.1716 1 12 1C12.8284 1 13.5 1.61561 13.5 2.375C13.5 3.13439 12.8284 3.75 12 3.75ZM12 23C11.1716 23 10.5 22.3844 10.5 21.625C10.5 20.8656 11.1716 20.25 12 20.25C12.8284 20.25 13.5 20.8656 13.5 21.625C13.5 22.3844 12.8284 23 12 23Z"
          stroke="#6E4C3E"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_331_10182">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(24) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CardMenuIcon;
