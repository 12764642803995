function AlarmIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      fill="none"
      viewBox="0 0 44 44"
    >
      <path
        fill="#FBF1ED"
        d="M0 8a8 8 0 018-8h28a8 8 0 018 8v28a8 8 0 01-8 8H8a8 8 0 01-8-8V8z"
      ></path>
      <path
        stroke="#6E4C3E"
        strokeLinecap="round"
        strokeWidth="2"
        d="M22 14.474c-4.97 0-9 4.147-9 9.263S17.03 33 22 33s9-4.147 9-9.263-4.03-9.263-9-9.263zm0 0V11m0 8.105v4.632M19.75 11h4.5m4.114 6.187l1.511-1.555"
      ></path>
    </svg>
  );
}

export default AlarmIcon;
