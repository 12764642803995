import React from "react";

const SettingIcon = ({ width, height }) => {
  return (
    <div className="iconWrapper">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 72 72"
        fill="none"
      >
        <path
          d="M32.8477 26.9341C32.8477 25.8659 33.6749 25 34.6953 25H37.3047C38.3251 25 39.1522 25.8659 39.1522 26.9341C39.1522 28.423 40.6919 29.3536 41.9236 28.6091C42.8073 28.075 43.9373 28.392 44.4475 29.3171L45.7522 31.6828C46.2624 32.6079 45.9596 33.7908 45.0759 34.3249C43.8442 35.0694 43.8442 36.9305 45.0759 37.675C45.9596 38.2091 46.2624 39.392 45.7522 40.3171L44.4475 42.6828C43.9373 43.6079 42.8073 43.9248 41.9236 43.3907C40.6919 42.6463 39.1522 43.5769 39.1522 45.0658C39.1522 46.134 38.325 47 37.3046 47H34.6953C33.6749 47 32.8477 46.134 32.8477 45.0658C32.8477 43.5769 31.3081 42.6463 30.0764 43.3907C29.1927 43.9248 28.0627 43.6079 27.5525 42.6828L26.2478 40.3171C25.7376 39.392 26.0404 38.2091 26.9241 37.675C28.1558 36.9305 28.1558 35.0693 26.9241 34.3249C26.0404 33.7908 25.7376 32.6079 26.2478 31.6828L27.5525 29.3171C28.0627 28.392 29.1927 28.075 30.0764 28.6091C31.3081 29.3536 32.8477 28.423 32.8477 26.9341Z"
          stroke="#9CC5A7"
          strokeWidth="2"
        />
        <path
          d="M40.203 36C40.203 38.4301 38.3212 40.4 36 40.4C33.6787 40.4 31.7969 38.4301 31.7969 36C31.7969 33.5699 33.6787 31.6 36 31.6C38.3212 31.6 40.203 33.5699 40.203 36Z"
          stroke="#9CC5A7"
          strokeWidth="2"
        />
      </svg>
    </div>
  );
};
export default SettingIcon;
