import { useState } from "react";

const Button = ({ title, onClick, className, hoverClassName }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const buttonClass = isHovered ? hoverClassName : className || "btn-class";
  return (
    <div>
      {/* <button className={buttonClass}  */}
      <button
        className={className ? className : "btn-class"}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {title}
      </button>
    </div>
  );
};

export default Button;
