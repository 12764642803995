function BellIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 44 44"
      fill="none"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H36C40.4183 0 44 3.58172 44 8V36C44 40.4183 40.4183 44 36 44H8C3.58172 44 0 40.4183 0 36V8Z"
        fill="#FBF1ED"
      />
      <path
        d="M25.4346 31.706C24.5256 32.5113 23.3316 33 22.0239 33C20.7162 33 19.5222 32.5113 18.6132 31.706M29.7553 22.8811V18.7647C29.7553 14.4653 26.3068 11 22.0239 11C17.7411 11 14.2458 14.3176 14.2458 18.7647V22.8532C14.2458 23.4785 14.1487 24.0997 13.9583 24.6929L13.0063 27.6584C12.9802 27.7396 13.0384 27.8234 13.1209 27.8234H30.8714C30.9586 27.8234 31.0204 27.7382 30.9937 27.6549L30.0353 24.6693C29.8498 24.0915 29.7553 23.4882 29.7553 22.8811Z"
        stroke="#6E4C3E"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default BellIcon;
