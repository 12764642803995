import React from "react";
import { Table } from "antd";
import "./style.css";

const onChange = (pagination, filters, sorter, extra) => {};

const CustomTable = ({
  isSkelton,
  columns,
  data,
  onRow,
  selectedRowKey,
  pagination,
}) => {
  const styles = {
    selectedRow: {
      backgroundColor: "#daf4e1",
    },
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        // onChange={onChange}
        pagination={pagination}
        className="DashboardTable"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (onRow) {
                onRow(record);
              }
            },
            style: record?.key === selectedRowKey ? styles.selectedRow : null,
          };
        }}
      />
    </div>
  );
};
CustomTable.defaultProps = {
  onRow: null,
  selectedRowKey: null,
  pagination: true,
};

export default CustomTable;
