import { Col, Row } from "react-bootstrap";

import "react-loading-skeleton/dist/skeleton.css";
import { useEffect, useRef, useState } from "react";
import { SideBar } from "../../components/SideBar";
import "./style.css";
import RightSideBar from "../../components/RightSideBar/RightSideBar";
import { EmployeeDashboard } from "../EmployeeDashboard";

import { Loading } from "../Loading";
import React from "react";
import Header from "../../components/Header/Header";
import axios from "axios";
import Swal from "sweetalert2";

const Layout = ({ isAdmin, isHead, children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const token = sessionStorage.getItem("token");

  const [isSkelton, setIsSkelton] = useState(true);
  const [selectedPage, setSelectedPage] = useState("dashboard");
  const [data, setData] = useState();
  const [count, setCount] = useState();
  const [ComplaintCount, setComplaintCount] = useState();
  const [UpdatedData, setUpdatedData] = useState();
  const [tableData, setTableData] = useState();
  const [tableDataRequests, setTableDataRequests] = useState();
  const [tableDataComplaints, setTableDataComplaints] = useState();
  const [tableDataSuggestions, setTableDataSuggestions] = useState();
  const [tableDataSupports, setTableDataSupports] = useState();

  const [employees, setEmployees] = useState([]);
  const [SelectedEmployee, setSelectedEmployee] = useState("");
  const [AppointmentDate, setAppointmentDate] = useState("");
  const [RefetchData, setRefetchData] = useState();

  const handleAppointmentDate = (date, dateString) => {
    setAppointmentDate(dateString);
  };
  const handleAssignRequest = async () => {
    if (UpdatedData && SelectedEmployee !== "" && token !== "") {
      setIsLoading(true);
      let data = {
        request_id: Number(UpdatedData?.number),
        employee_id: Number(SelectedEmployee),
        token: token,
      };
      axios
        .post(
          "https://appadmin.buyinpk.com/portalapis/ServiceRequests/AssignToEmployee",
          data
        )
        .then((res) => {
          // console.log(res?.data);
          fetchRequestData();
          setIsLoading(false);
          // setUpdatedData("");
          // alert(res?.data?.msg);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            // icon: "success",
            title: res?.data?.msg,
          });
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          // alert("something went wrong");
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: "something went wrong",
          });
        });
    }
    // else {
    // }
  };
  const handleAppointment = async () => {
    if (AppointmentDate !== "" && UpdatedData) {
      setIsLoading(true);
      const user = JSON.parse(sessionStorage.getItem("user"));
      const token = sessionStorage.getItem("token");
      let data = {
        employee_id: user?.id,
        token: token,
        request_id: UpdatedData?.number,
        appointment_date: AppointmentDate,
      };
      axios
        .post(
          "https://appadmin.buyinpk.com/portalapis/ServiceRequests/updateAppointments",
          data
        )
        .then((res) => {
          // console.log(res?.data);
          setRefetchData((prev) => !prev);
          fetchRequestData();
          // setUpdatedData("");
          setIsLoading(false);
          // alert(res?.data?.msg);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            // icon: "success",
            title: res?.data?.msg,
          });
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
    // else {
    // }
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://appadmin.buyinpk.com/portalapis/Employees/list`
        );
        setEmployees(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log("Error fetching employees:", error);
      }
    };
    const fetchCoutData = async () => {
      const user = JSON.parse(sessionStorage.getItem("user"));
      const token = sessionStorage.getItem("token");
      const body = {
        employee_id: user?.id,
        token: token,
      };
      try {
        const response = await axios.post(
          `https://appadmin.buyinpk.com/portalapis/Employees/getRequestCounts`,
          body
        );
        setCount(response.data);
      } catch (error) {
        console.log("Error fetching count:", error);
      }
    };
    const fetchComplaintCoutData = async () => {
      const user = JSON.parse(sessionStorage.getItem("user"));
      const token = sessionStorage.getItem("token");
      const body = {
        userid: user?.id,
        department_id: user?.department_id,
        token: token,
      };
      try {
        const response = await axios.get(
          `https://appadmin.buyinpk.com/portalapis/SuggestionComplaints/countbox`,
          body
        );
        setComplaintCount(response.data);
      } catch (error) {
        console.log("Error fetching count:", error);
      }
    };

    fetchData();
    fetchComplaintCoutData();
    fetchCoutData();
  }, [RefetchData]);

  // Function to filter data

  const [ListStatus, setListStatus] = useState("PENDING");

  useEffect(() => {
    if (selectedPage === "dashboard") {
      setListStatus("PENDING");
    }
  }, [selectedPage]);

  useEffect(() => {
    if (
      ListStatus === "Pending" ||
      ListStatus === "PENDING" ||
      ListStatus === "Done" ||
      ListStatus === "On progress" ||
      ListStatus === "Rejected" ||
      ListStatus === "Assigned to Me" ||
      ListStatus === "Appointment"
    ) {
      fetchRequestData();
    } else if (ListStatus === "Suggestions") {
      fetchSuggestionsData();
    } else if (ListStatus === "Complaints") {
      fetchComplaintData();
    } else if (ListStatus === "Tech Supports") {
      fetchSupportsData();
    }
  }, [ListStatus]);

  useEffect(() => {
    if (selectedPage === "headphone") {
      fetchComplaintData();
    }
    // fetchSupportsData()
    // fetchSuggestionsData()
  }, [selectedPage]);

  // Function to fetch data
  const fetchComplaintData = () => {
    setIsLoading(true);
    setIsSkelton(false);
    const user = JSON.parse(sessionStorage.getItem("user"));
    const token = sessionStorage.getItem("token");

    const body = {
      userid: user?.id,
      department_id: user?.department_id,
      token: token,
    };

    axios
      .post(
        "https://appadmin.buyinpk.com/portalapis/SuggestionComplaints/complaints",
        body
      )
      .then((res) => {
        // console.log("🚀 ~ .then ~ res:", res?.data)
        var arr = [];
        res?.data?.data?.map((d, index) => {
          arr.push({
            key: index,
            number: d?.id,
            request_type: d?.request_type,
            customer: d?.customer?.fullname,
            emiratesid: d?.customer?.emiratesid,
          });
        });
        setData(res?.data);
        setTableDataComplaints(arr);
        setTableData(arr);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const fetchSuggestionsData = () => {
    setIsLoading(true);
    setIsSkelton(false);
    const user = JSON.parse(sessionStorage.getItem("user"));
    const token = sessionStorage.getItem("token");

    const body = {
      userid: user?.id,
      department_id: user?.department_id,
      token: token,
    };

    axios
      .post(
        "https://appadmin.buyinpk.com/portalapis/SuggestionComplaints/suggestions",
        body
      )
      .then((res) => {
        // console.log("🚀 ~ .then ~ res:", res?.data)
        var arr = [];
        res?.data?.data?.map((d, index) => {
          arr.push({
            key: index,
            number: d?.id,
            request_type: d?.request_type,
            customer: d?.customer?.fullname,
            emiratesid: d?.customer?.emiratesid,
          });
        });
        setData(res?.data);
        setTableDataSuggestions(arr);
        setTableData(arr);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const fetchSupportsData = () => {
    setIsLoading(true);
    setIsSkelton(false);
    const user = JSON.parse(sessionStorage.getItem("user"));
    const token = sessionStorage.getItem("token");

    const body = {
      userid: user?.id,
      department_id: user?.department_id,
      token: token,
    };

    axios
      .post(
        "https://appadmin.buyinpk.com/portalapis/SuggestionComplaints/techsupport",
        body
      )
      .then((res) => {
        // console.log("🚀 ~ .then ~ res:", res?.data)
        var arr = [];
        res?.data?.data?.map((d, index) => {
          arr.push({
            key: index,
            number: d?.id,
            request_type: d?.request_type,
            customer: d?.customer?.fullname,
            emiratesid: d?.customer?.emiratesid,
          });
        });
        setData(res?.data);
        setTableDataSupports(arr);
        setTableData(arr);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const fetchRequestData = () => {
    setIsLoading(true);
    setIsSkelton(false);
    const user = JSON.parse(sessionStorage.getItem("user"));
    const token = sessionStorage.getItem("token");
    const positionsID = user?.positions.map((item) => item?.designation_id);

    const body = {
      employee_id: user?.id,
      token: token,
      position_id: positionsID,
      department_id: user?.department_id,
      status:
        ListStatus === "pending"
          ? "PENDING"
          : ListStatus === "Rejected"
          ? "REJECTED"
          : ListStatus === "On progress"
          ? "IN_PROGRES"
          : ListStatus === "Done"
          ? "APPROVED"
          : ListStatus === "Assigned to Me"
          ? "AssignedToMe"
          : "PENDING",

      appointments: ListStatus === "Appointment" ? 1 : 0,
    };

    axios
      .post(
        "https://appadmin.buyinpk.com/portalapis/ServiceRequests/list",
        body
      )
      .then((res) => {
        // console.log("🚀 ~ .then ~ res:", res?.data)
        var arr = [];
        res?.data?.data?.map((d, index) => {
          arr.push({
            key: index,
            number: d?.id,
            name: d?.service?.name,
            section: d?.customer?.emiratesid,
            tags: d?.request_status,
            position: d?.customer?.fullname,
            date: d?.created,
            status: d?.request_status,
            fullData: d,
          });
        });
        setData(res?.data);
        setTableDataRequests(arr);
        setTableData(arr);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    filterData(value);
  };

  const filterData = (input) => {
    if (selectedPage === "headphone" && ListStatus === "Complaints") {
      const filteredData = tableDataComplaints.filter((item) => {
        const name = item.customer ? item.customer.toLowerCase() : "";
        const type = item.request_type ? item.request_type.toLowerCase() : "";
        const emiratesid = item.emiratesid ? item.emiratesid.toLowerCase() : "";

        const lowerInput = input.toLowerCase();

        return (
          name.includes(lowerInput) ||
          type.includes(lowerInput) ||
          emiratesid.includes(lowerInput)
        );
      });

      setTableData(filteredData);
    } else if (selectedPage === "headphone" && ListStatus === "Suggestions") {
      const filteredData = tableDataSuggestions.filter((item) => {
        const name = item.customer ? item.customer.toLowerCase() : "";
        const type = item.request_type ? item.request_type.toLowerCase() : "";
        const emiratesid = item.emiratesid ? item.emiratesid.toLowerCase() : "";

        const lowerInput = input.toLowerCase();

        return (
          name.includes(lowerInput) ||
          type.includes(lowerInput) ||
          emiratesid.includes(lowerInput)
        );
      });

      setTableData(filteredData);
    } else if (selectedPage === "headphone" && ListStatus === "Tech Supports") {
      const filteredData = tableDataSupports.filter((item) => {
        const name = item.customer ? item.customer.toLowerCase() : "";
        const type = item.request_type ? item.request_type.toLowerCase() : "";
        const emiratesid = item.emiratesid ? item.emiratesid.toLowerCase() : "";

        const lowerInput = input.toLowerCase();

        return (
          name.includes(lowerInput) ||
          type.includes(lowerInput) ||
          emiratesid.includes(lowerInput)
        );
      });

      setTableData(filteredData);
    } else {
      const filteredData = tableDataRequests.filter((item) => {
        const name = item.name ? item.name.toLowerCase() : "";
        const section = item.section ? item.section.toLowerCase() : "";
        const position = item.position ? item.position.toLowerCase() : "";

        const lowerInput = input.toLowerCase();

        return (
          name.includes(lowerInput) ||
          section.includes(lowerInput) ||
          position.includes(lowerInput)
        );
      });

      setTableData(filteredData);
    }
  };

  // useEffect(() => {
  //   setIsLoading(true);
  //   setIsSkelton(false);
  //   const user = JSON.parse(sessionStorage.getItem("user"));
  //   const token = sessionStorage.getItem("token");
  //   // console.log(user, token);
  //   let data = JSON.stringify({
  //     userid: user?.id,
  //     token: token,
  //   });

  //   axios
  //     .post("https://appadmin.buyinpk.com/portalapis/Employees/dashboard", data)
  //     .then((res) => {
  //       // console.log(res?.data);
  //       const arr = [];
  //       res?.data?.requests?.data?.map((d, index) => {
  //         // console.log("🚀 ~ res?.data?.requests?.data?.map ~ d:", d);
  //         const requestDataArr = d?.request?.map((r, index) => ({
  //           requestData: r,
  //         }));
  //         arr.push({
  //           key: index,
  //           number: d?.id,
  //           name: d?.service?.name,
  //           section: d?.customer?.emiratesid,
  //           tags: d?.request_status,
  //           position: d?.customer?.fullname,
  //           date: d?.created,
  //           status: d?.request_status,
  //           fullData: d,
  //           requestData: requestDataArr,
  //         });
  //       });
  //       setData(res?.data);
  //       setTableDataRequests(arr);
  //       setTableData(arr);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setIsLoading(false);
  //     });

  //   // axios
  //   //   .post("https://appadmin.buyinpk.com/portalapis/SuggestionComplaints/list")
  //   //   .then((res) => {
  //   //     // console.log(res?.data);
  //   //     const arr = [];
  //   //     res?.data?.data?.map((d) => {
  //   //       // console.log(d, "d");
  //   //       arr.push({
  //   //         id: d?.id,
  //   //         request_type: d?.request_type,
  //   //         subject: d?.subject,
  //   //         message: d?.message,
  //   //         created: d?.created,
  //   //         requester_name: d?.customer?.fullname,
  //   //         emiratesid: d?.customer?.emiratesid,
  //   //         tags: d?.request_status,
  //   //         position: d?.customer?.fullname,
  //   //       });
  //   //     });
  //   //     setData(res?.data);
  //   //     setTableDataComplaints(arr);
  //   //     setIsLoading(false);
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log(err);
  //   //     setIsLoading(false);
  //   //   });
  // }, []);

  useEffect(() => {
    if (selectedPage == "dashboard") {
      setTableData(tableDataRequests);
    } else if (
      selectedPage == "headphone" &&
      (ListStatus == "Complaints" ||
        ListStatus === "Pending" ||
        ListStatus === "PENDING")
    ) {
      setTableData(tableDataComplaints);
    } else if (selectedPage == "headphone" && ListStatus == "Tech Supports") {
      setTableData(tableDataSupports);
    } else if (selectedPage == "headphone" && ListStatus == "Suggestions") {
      setTableData(tableDataSuggestions);
    }
  }, [selectedPage, ListStatus]);
  const [isRightSidebar, setIsRightSidebar] = useState(true);
  const [leftWidth, setLeftWidth] = useState(isAdmin ? "85%" : "65%");
  const containerRef = useRef();

  const handleResize = (e) => {
    if (containerRef.current) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", () => {
        document.removeEventListener("mousemove", handleMouseMove);
      });
    }
  };

  const handleMouseMove = (e) => {
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const newLeftWidth = `${
        ((e.clientX - containerRect.left) / containerRect.width) * 100
      }%`;
      setLeftWidth(newLeftWidth);
    }
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="layout-container">
          <Col className="sidebar-col" lg={1}>
            <SideBar
              isSkelton={isSkelton}
              setSelectedPage={setSelectedPage}
              selectedPage={selectedPage}
            />
          </Col>
          <div
            ref={containerRef}
            id="container"
            className="resizable-container"
          >
            <div style={{ width: leftWidth }} className="resizable-left">
              <Col>
                <Col>
                  <Row>
                    <Header
                      data={data}
                      setLeftWidth={setLeftWidth}
                      leftWidth={leftWidth}
                      selectedPage={selectedPage}
                    />
                  </Row>
                  <Row>
                    <EmployeeDashboard
                      count={count}
                      ComplaintCount={ComplaintCount}
                      data={data}
                      setLeftWidth={setLeftWidth}
                      leftWidth={leftWidth}
                      setIsRightSidebar={setIsRightSidebar}
                      isRightSidebar={isRightSidebar}
                      setSelectedEmployee={setSelectedEmployee}
                      isSkelton={isSkelton}
                      selectedPage={selectedPage}
                      tableData={tableData}
                      rowData={setUpdatedData}
                      setListStatus={setListStatus}
                      ListStatus={ListStatus}
                      handleSearchChange={handleSearchChange}
                    />
                  </Row>
                </Col>
              </Col>
            </div>
            <div className="resizable-divider" onMouseDown={handleResize}></div>
            <div
              style={{ width: `calc(100% - ${leftWidth})` }}
              className="resizable-right"
            >
              {isRightSidebar && (
                <Col>
                  <RightSideBar
                    data={UpdatedData}
                    isAdmin={isAdmin}
                    isSkelton={isSkelton}
                    setLeftWidth={setLeftWidth}
                    leftWidth={leftWidth}
                    selectedPage={selectedPage}
                    employees={employees}
                    setSelectedEmployee={setSelectedEmployee}
                    SelectedEmployee={SelectedEmployee}
                    handleAssignRequest={handleAssignRequest}
                    RefetchRequestList={fetchRequestData}
                    handleAppointmentDate={handleAppointmentDate}
                    AppointmentDate={AppointmentDate}
                    handleAppointment={handleAppointment}
                    setRefetchData={setRefetchData}
                    fetchRequestData={fetchRequestData}
                    setUpdatedData={setUpdatedData}
                  />
                </Col>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Layout;
