function RejectIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.375 6.56569L12 9.90511M12 9.90511L8.625 13.2445M12 9.90511L15.375 13.2445M12 9.90511L8.625 6.5657M5.25 1H18.75C19.9926 1 21 1.99674 21 3.22628V19.6606C21 21.3156 19.2398 22.392 17.7438 21.6518L17.5062 21.5343C16.8728 21.2209 16.1272 21.2209 15.4938 21.5343L13.0062 22.765C12.3728 23.0783 11.6272 23.0783 10.9938 22.765L8.50623 21.5343C7.87279 21.2209 7.12721 21.2209 6.49377 21.5343L6.25623 21.6518C4.7602 22.392 3 21.3156 3 19.6606V3.22628C3 1.99674 4.00736 1 5.25 1Z"
        stroke="#C10606"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default RejectIcon;
